:root {
  --no-of-slides: 30;
  --slide-width: 250px;
  --slide-height: 100px;
  --iteration-time: 100s;
	--linear-gradient: linear-gradient(to right,  rgba(255, 255, 255, 0.8) 0%,rgba(255,255,255,0) 20%);
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(var(--slide-width) * var(--no-of-slides) * -1)); }
}

.slider-body {
	align-items: center;
	display: flex;
}

.slider {
	width: calc(2 * var(--slide-width));
	margin: auto;
	overflow: hidden;
	position: relative;
}

.slider::after,::before {
	height: var(--slide-height);
	width: var(--slide-width);
	position: absolute;
	z-index: 2;
}

.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
	background: var(--linear-gradient);
}

.slider::before {
	left: 0;
	top: 0;
	background: var(--linear-gradient);
}

.slide-track {
	animation: scroll var(--iteration-time) linear infinite;
	display: flex;
	width: calc(var(--slide-width) * var(--no-of-slides));
}

.slide {
	width: var(--slide-width);
}

.slide-img {
	width: var(--slide-width);
	vertical-align: baseline;
	bottom: 0;
	left: 0;
}

@media screen and (max-width: 960px) {
	.slider-body {
		justify-content: center;
	}
  .slider {
		width: calc(2 * var(--slide-width));
	}
	.slide-img {
		padding: 0 0.5rem;
	}
}

@media screen and (max-width: 640px) {
	.slider-body {
		justify-content: center;
	}
  .slider {
		width: calc(1.3 * var(--slide-width));
	}
	.slide-img {
		padding: 0 1rem;
	}
}